import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../../image/aid-row.png";

const Header = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const moveUrl = (url) => {
    // 새탭 열기로 사이트 이동
    window.open(url);
  };

  return (
    <div className='Header'>
      <div className='max-w-[1128px] mx-auto flex justify-between items-center py-12'>
        <div className='w-auto h-10'>
          <img
            src={logo}
            className='h-full'
          />
        </div>

        <div className='flex space-x-3'>
          <button
            className='bg-aid-orange-200 text-aid-orange-500 border border-aid-orange-500 rounded-[12px] py-[10px] px-[24px]'
            onClick={() => moveUrl("https://aid-raw.com/login")}>
            로그인
          </button>
          <button
            className='bg-gray-100 border border-gray-300 rounded-[12px] py-[10px] px-[24px]'
            onClick={() => moveUrl("https://aid-raw.com/signup")}>
            회원가입
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
