import React, { useEffect, useState } from "react";
import MediaBox from "./MediaBox";
import Introduce from "./Introduce";
import About from "./About";
import HowToUse from "./HowToUse";
import Plan from "./Plan";
import FooterBanner from "./FooterBanner";

const BodyFrame = () => {
  return (
    <div className='flex flex-col BodyFrame space-y-[120px] justify-center items-center'>
      <MediaBox />
      <Introduce />
      <About />
      <HowToUse />
      <Plan />
      <FooterBanner />
    </div>
  );
};

export default BodyFrame;
