import React from 'react'

import lock from '../../image/lock.png'
import key from '../../image/key.png'
import layer from '../../image/layer.png'

const Introduce = () => {
  return (
    <div className='Introduce flex flex-col justify-center items-center'>
      <div className='text-5xl'>
        최초의, 최고의 <span className='text-main-color'>웹툰 전용 AI</span>
      </div>

      <div className='text-lg mt-5'>
        웹툰만을 위해 만들어진 국내 최초의 AI 서비스이자 독보적인 퀄리티의 AI
        서비스
      </div>

      <div className='flex justify-center space-x-[32px] mt-[60px]'>
        <div className='flex flex-col space-y-[16px] px-6 text-center border border-gray-300 shadow rounded-[12px] p-6'>
          <div className='h-[100px] mx-auto'>
            <img src={lock} className='h-full' />
          </div>
          <div className='text-main-color text-xl font-semibold'>
            강력한 저작권 보호
          </div>
          <div className='w-[210px] h-[144px] text-gray-500'>
            내 작품들을 베이스로
            <br /> 이미지를 생성
            <br />그 어느 이미지와 다른
            <br />
            나만의 작품
            <br />
            <br />
            저작권은 오로지 나에게만
            <br />
          </div>
        </div>

        <div className='flex flex-col space-y-[16px] px-6 text-center border border-gray-300 shadow rounded-[12px] p-6'>
          <div className='h-[100px] mx-auto'>
            <img src={key} className='h-full' />
          </div>
          <div className='text-main-color text-xl font-semibold'>
            편리한 AiD 제작
          </div>
          <div className='w-[210px] h-[144px] text-gray-500'>
            가이드에 맞춰 그려진
            <br />단 7장의 개인 캐릭터
            <br />
            이미지를 통해
            <br />
            <br />
            나만의 보조작가 AI를
            <br />
            편리하게 생성
            <br />
          </div>
        </div>

        <div className='flex flex-col space-y-[16px] px-6 text-center border border-gray-300 shadow rounded-[12px] p-6'>
          <div className='h-[100px] mx-auto'>
            <img src={layer} className='h-full' />
          </div>
          <div className='text-main-color text-xl font-semibold'>
            작업하기 편한 결과물
          </div>
          <div className='w-[210px] h-[144px] text-gray-500'>
            선화 레이어, 밑색 레이어,
            <br />
            명암 및 후보정 레이어를
            <br />
            분리하여 제공
            <br />
            <br />
            기존 공정과 유사한 결과물,
            <br />
            편리한 수정
            <br />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Introduce
