import React from "react";

import navigate_next from "../../image/navigate_next.png";
import file_download from "../../image/file_download.png";
import line_guide from "../../image/line_guide.png";
import line_icon from "../../image/line_icon.png";
import color_guide from "../../image/color_guide.png";
import color_icon from "../../image/color_icon.png";
import shadow_guide from "../../image/shadow_guide.png";
import shadow_icon from "../../image/shadow_icon.png";

const HowToUse = () => {
  return (
    <div className='flex flex-col items-center justify-center HowToUse max-w-[1128px]'>
      <div className='text-5xl font-semibold'>AiD 사용방법</div>
      <div className='mt-3'>
        AiD로 캐릭터를 등록하고 사용하는 방법은 아래와 같습니다.
      </div>

      <div className='flex'>
        <div className='flex flex-col mt-20 space-y-12 pt-[20px]'>
          {new Array(4).fill(0).map((_, index) => (
            <>
              <div
                key={index}
                className='flex items-center'>
                <div className='rounded-full bg-aid-orange-200 w-[100px] h-[100px] flex justify-center items-center text-center'>
                  <div className='text-5xl text-aid-orange-500'>
                    {index + 1}
                  </div>
                </div>
                <div className='bg-aid-orange-200 h-[2px] w-[42px]'></div>
              </div>

              <img
                src={navigate_next}
                className='w-[33px] transform rotate-90 ml-8'
              />
            </>
          ))}

          <div className='rounded-full bg-aid-orange-200 w-[100px] h-[100px] flex justify-center items-center'>
            <img
              src={file_download}
              alt=''
            />
          </div>
        </div>

        <div className='flex flex-col mt-20'>
          <div className='bg-gray-100 w-[406px] p-6 flex flex-col rounded-[12px] justify-start space-y-2'>
            <div className='text-lg font-semibold'>
              AiD에 자신만의 캐릭터를 등록한다.
            </div>
            <div className='leading-5'>
              단 7장의 이미지를 통해 나만의 캐릭터를
              <br />
              AiD에 의상과 함께 등록합니다.
            </div>
          </div>

          <div className='flex justify-start mt-[28px]'>
            <div className='bg-gray-100 w-[406px] p-6 rounded-l-[12px] space-y-2'>
              <div className='w-[50px] h-[50px]'>
                <img
                  src={line_icon}
                  alt=''
                />
              </div>
              <div className='text-lg font-semibold'>선화 작업</div>
              <div>: 콘티를 삽입해보세요!</div>

              <div className='leading-5'>
                그리고 싶은 구도의{" "}
                <span className='text-main-color'>콘티를 삽입해보세요.</span>
                <br />내 캐릭터의 외모, 의상, 그림체를
                <br />
                그대로 반영하여{" "}
                <span className='text-main-color'>선화를 그려줍니다.</span>
              </div>
            </div>

            <div className='bg-gray-50 w-[435px] p-6 rounded-r-[12px]'>
              <img
                src={line_guide}
                alt=''
              />
            </div>
          </div>

          <div className='flex justify-start mt-[28px]'>
            <div className='bg-gray-100 w-[406px] p-6 rounded-l-[12px] space-y-2'>
              <div className='w-[50px] h-[50px]'>
                <img
                  src={color_icon}
                  alt=''
                />
              </div>
              <div className='text-lg font-semibold'>밑색 작업</div>
              <div>: 선화를 삽입해보세요!</div>

              <div className='leading-5'>
                그리고 싶은 구도의{" "}
                <span className='text-main-color'>선화를 삽입해보세요.</span>
                <br />내 캐릭터의 외모, 의상, 그림체를
                <br />
                그대로 반영하여{" "}
                <span className='text-main-color'>밑색를 그려줍니다.</span>
              </div>
            </div>

            <div className='bg-gray-50 w-[435px] p-6 rounded-r-[12px]'>
              <img
                src={color_guide}
                alt=''
              />
            </div>
          </div>

          <div className='flex justify-start mt-[28px]'>
            <div className='bg-gray-100 w-[406px] p-6 rounded-l-[12px] space-y-2'>
              <div className='w-[50px] h-[50px]'>
                <img
                  src={shadow_icon}
                  alt=''
                />
              </div>
              <div className='text-lg font-semibold'>명암 및 후보정</div>
              <div>: 밑색 결과물을 삽입해보세요!</div>

              <div className='leading-5'>
                밑색 채색이 완료된 결과물을 삽입해보세요.
                <br />
                명암 및 후보정 작업이 완료된
                <br />
                <span className='text-main-color'>
                  최종 결과물을 완성해줍니다.
                </span>
              </div>
            </div>

            <div className='bg-gray-50 w-[435px] p-6 rounded-r-[12px]'>
              <img
                src={shadow_guide}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToUse;
