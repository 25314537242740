import './App.css'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/Header/Header'
import BodyFrame from './components/Body/BodyFrame'
import Footer from './components/Footer/Footer'

function App() {
  return (
    <div className='flex flex-col h-screen'>
      <Router>
        <Header />
        <BodyFrame />
        <Footer />
      </Router>
    </div>
  )
}

export default App
