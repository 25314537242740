import React from "react";

import t_shirt from "../../image/t_shirt.png";
import ticket from "../../image/ticket.png";
import tickets from "../../image/tickets.png";
import infinity from "../../image/infinity.png";
import spanner from "../../image/spanner.png";
import navigate_next from "../../image/navigate_next.png";

const Plan = () => {
  return (
    <div className='flex flex-col items-center justify-center text-center Plan'>
      <div className='text-5xl font-semibold'>AiD 플랜정책</div>
      <div className='mt-4'>
        AiD는 더 나은 서비스를 제공하고자 이와 같은 가격정책을 두고 있습니다.
      </div>

      <div className='flex justify-center items-center text-center space-x-4 mt-[60px]'>
        <div className='p-4 bg-gray-100 shadow rounded-[12px]'>
          <div className='text-xl'>Basic Plan</div>
          <div className='mt-3'>최대 보유 의상 1개</div>
          <div className='mt-3'>매월 최대 30회</div>

          <hr className='w-[120px] border-gray-500 my-10 mx-auto' />

          <div className='flex space-x-6 w-[200px] justify-center'>
            <div className='flex flex-col items-center justify-center space-y-2 text-cent'>
              <img
                src={t_shirt}
                className='w-[55px]'
              />
              <div className='text-main-color'>1개</div>
            </div>
            <div className='flex flex-col items-center justify-center space-y-2 text-cent'>
              <img
                src={ticket}
                className='w-[50px]'
              />
              <div className='text-main-color'>30회</div>
            </div>
          </div>
        </div>

        <div className='p-4 bg-gray-100 shadow rounded-[12px]'>
          <div className='text-xl'>Standard Plan</div>
          <div className='mt-3'>최대 보유 의상 4개</div>
          <div className='mt-3'>매월 최대 120회</div>

          <hr className='w-[120px] border-gray-500 my-10 mx-auto' />

          <div className='flex space-x-6 w-[200px] justify-center'>
            <div className='flex flex-col items-center justify-center space-y-2 text-cent'>
              <img
                src={t_shirt}
                className='w-[55px]'
              />
              <div className='text-main-color'>4개</div>
            </div>
            <div className='flex flex-col items-center justify-center space-y-2 text-cent'>
              <img
                src={tickets}
                className='w-[50px]'
              />
              <div className='text-main-color'>120회</div>
            </div>
          </div>
        </div>

        <div className='p-4 bg-gray-100 shadow rounded-[12px]'>
          <div className='text-xl'>Premium Plan</div>
          <div className='mt-3'>최대 보유 의상 12개</div>
          <div className='mt-3'>매월 최대 무제한</div>

          <hr className='w-[120px] border-gray-500 my-10 mx-auto' />

          <div className='flex space-x-6 w-[200px] justify-center'>
            <div className='flex flex-col items-center justify-center space-y-2 text-cent'>
              <img
                src={t_shirt}
                className='w-[55px]'
              />
              <div className='text-main-color'>12개</div>
            </div>
            <div className='flex flex-col items-center justify-center space-y-2 text-cent'>
              <img
                src={infinity}
                className='w-[50px]'
              />
              <div className='text-main-color'>무제한</div>
            </div>
          </div>
        </div>

        <div className='p-4 bg-gray-100 shadow rounded-[12px]'>
          <div className='text-xl'>Enterprise</div>
          <div className='mt-3'>최대 보유 의상 맞춤조정</div>
          <div className='mt-3'>매월 최대 맞춤조정</div>

          <hr className='w-[120px] border-gray-500 my-10 mx-auto' />

          <div className='flex space-x-6 w-[200px] justify-center'>
            <div className='flex flex-col items-center justify-center space-y-2 text-cent'>
              <img
                src={t_shirt}
                className='w-[55px]'
              />
              <div className='text-main-color'>맞춤조정</div>
            </div>
            <div className='flex flex-col items-center justify-center space-y-2 text-cent'>
              <img
                src={spanner}
                className='w-[50px]'
              />
              <div className='text-main-color'>맞춤조정</div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex items-center justify-between w-full mt-8'>
        <div>*위 상품의 최대 이용기간은 3개월입니다.</div>
        {/* <div className='flex items-center'>
          <div className='mr-2 text-main-color' onClick={() => moveUrl('https://')}>환불규정</div>
          <img src={navigate_next} alt='' className='h-[20px]' />
        </div> */}
      </div>
    </div>
  );
};

export default Plan;
