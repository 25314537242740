import arrow_forward_24px from "../../image/arrow_forward_24px.png";

const FooterBanner = () => {
  const moveUrl = (url) => {
    // 새탭 열기
    window.open(url);
  };

  return (
    <div className='FooterBanner bg-gray-100 w-full py-[124px] flex justify-center'>
      <div className='flex justify-center items-center w-[1128px] space-x-[120px]'>
        <div className='flex flex-col space-y-2'>
          <div className='text-5xl font-bold'>오직 나만을 위한 보조작가 AI</div>
          <div>내 작품으로, 내 작품을 위해 만들어진, 나만의 보조작가 AI</div>
        </div>

        <button
          className='bg-main-color rounded-[12px] py-[26px] px-[51px] text-white flex space-x-2'
          onClick={() => moveUrl("https://aid-raw.com/login")}>
          <div>바로 사용해보기</div>
          <img
            src={arrow_forward_24px}
            alt=''
          />
        </button>
      </div>
    </div>
  );
};

export default FooterBanner;
