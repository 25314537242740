import React, { useRef, useState, useEffect } from "react";

import logo from "../../image/logo_text_white.png";

const MediaBox = () => {
  const videoRef = useRef(null);
  const videoBoxRef = useRef(null);
  const videoInnerBoxRef = useRef(null);
  const videoInnerBoxOverlayRef = useRef(null);

  const moveUrl = (url) => {
    // 새탭 열기로 사이트 이동
    window.open(url);
  };

  useEffect(() => {
    let currentWidth = window.innerWidth - 16.32;
    // if (currentWidth < 1128) {
    //   currentWidth = 1128;
    // }
    videoRef.current.style.width = `${currentWidth}px`;
    videoBoxRef.current.style.width = `${currentWidth}px`;
    videoBoxRef.current.style.paddingBottom = `${(currentWidth * 9) / 16}px`;
    videoInnerBoxRef.current.style.width = `${currentWidth}px`;

    videoInnerBoxOverlayRef.current.style.width = `${currentWidth}px`;
    videoInnerBoxOverlayRef.current.style.height = `${
      (currentWidth * 9) / 16
    }px`;

    const padding = ((currentWidth * 9) / 16 - 250) / 2;
    videoInnerBoxRef.current.style.paddingTop = `${padding}px`;
    videoInnerBoxRef.current.style.paddingBottom = `${padding}px`;
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let currentWidth = window.innerWidth - 16.32;
      // if (currentWidth < 1128) {
      //   currentWidth = 1128;
      // }
      videoRef.current.style.width = `${currentWidth}px`;
      videoBoxRef.current.style.width = `${currentWidth}px`;
      videoBoxRef.current.style.paddingBottom = `${(currentWidth * 9) / 16}px`;
      videoInnerBoxRef.current.style.width = `${currentWidth}px`;

      videoInnerBoxOverlayRef.current.style.width = `${currentWidth}px`;
      videoInnerBoxOverlayRef.current.style.height = `${
        (currentWidth * 9) / 16
      }px`;

      const padding = ((currentWidth * 9) / 16 - 250) / 2;
      videoInnerBoxRef.current.style.paddingTop = `${padding}px`;
      videoInnerBoxRef.current.style.paddingBottom = `${padding}px`;
    });

    return () => {
      window.removeEventListener("resize", () => {
        console.log("remove");
      });
    };
  });

  return (
    <div className='flex flex-col justify-center MediaBox'>
      <div ref={videoBoxRef}>
        <video
          ref={videoRef}
          className='absolute'
          id='video'
          autoPlay
          loop
          muted
          playsInline
          src='https://cdn.aid-raw.com/contents/introduce_video.mp4'></video>
        <div className='relative'>
          <div
            className='absolute top-0 left-0 z-10 w-full h-full'
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            ref={videoInnerBoxOverlayRef}>
            <div
              className='z-20 flex flex-col items-center justify-center'
              ref={videoInnerBoxRef}>
              <div className='text-5xl text-center text-white'>
                오직 나만을 위한 보조작가{" "}
              </div>
              <div className='h-16 mt-5'>
                <img
                  className='w-auto h-full mx-auto'
                  src={logo}
                  alt=''
                />
              </div>

              <button
                className='py-[26px] px-[65px] text-xl rounded-[12px] bg-main-color text-white flex mt-10 mx-auto'
                onClick={() => moveUrl("https://aid-raw.com/login")}>
                나의 AiD 만들기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaBox;
