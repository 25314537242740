import React from "react";

import brain_logo from "../../image/brain_logo.png";
import navigate_next from "../../image/navigate_next.png";

const About = () => {
  return (
    <div className='About bg-gray-100 py-[60px] w-full flex flex-col justify-center items-center'>
      <div className='text-5xl font-semibold text-main-color'>AiD는?</div>

      <div className='mt-10 text-center'>
        '일반적인' AI는 웹툰에 사용될 수 없습니다.
        <br /> 웹툰에서는{" "}
        <span className='text-main-color'>
          그림체, 캐릭터 외모, 의상, 헤어 등이 일관되게 유지되어야 합니다.
        </span>
        <br />
        <br />
        AiD에서는{" "}
        <span className='text-main-color'>내 작품의 이미지 7컷만으로,</span>
        <br />
        ​이 모든 것을 정확하게 그려내는{" "}
        <span className='text-main-color'>'나만의 보조작가AI'</span>를 만들 수
        있습니다.
        <br />
        <br />
        복잡한 프롬프팅도, AI에 대한 이해도 필요하지 않습니다.
        <br />
        ​내가 만든 창작물만 있다면, 어떤 구도의 장면이든 손쉽게 그릴 수
        있습니다!
      </div>

      <div className='mt-[60px] flex justify-center items-center space-x-4 text-center'>
        <div className='p-6 bg-white rounded-[12px]'>
          <div className='text-3xl text-gray-500 w-[192px]'>필요 데이터</div>
          <div className='bg-main-color rounded-[12px] text-white mt-4 py-[10px] px-6'>
            단 7장의 이미지
          </div>
        </div>

        <div className='w-[33px]'>
          <img
            src={navigate_next}
            className='w-full'
          />
        </div>

        <div className='flex flex-col justify-center'>
          <img
            src={brain_logo}
            className='w-[134px]'
          />
          <div className='mt-6 text-xl text-center text-main-color'>AiD</div>
        </div>

        <div className='w-[33px]'>
          <img
            src={navigate_next}
            className='w-full'
          />
        </div>

        <div className='p-6 bg-white rounded-[12px]'>
          <div className='text-3xl text-gray-500 w-[192px]'>결과물</div>
          <div className='mt-4 text-sm text-main-color'>동일한</div>
          <div className='bg-main-color rounded-[12px] text-white mt-4 py-[10px] px-6 text-sm'>
            그림체
          </div>
          <div className='bg-main-color rounded-[12px] text-white mt-4 py-[10px] px-6 text-sm'>
            캐릭터 외모
          </div>
          <div className='bg-main-color rounded-[12px] text-white mt-4 py-[10px] px-6 text-sm'>
            의상
          </div>
          <div className='bg-main-color rounded-[12px] text-white mt-4 py-[10px] px-6 text-sm'>
            헤어
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
